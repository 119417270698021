import React, { useRef } from "react";
import PropTypes from "prop-types";

import "./SocialIcon.scss";

interface SocialIconProps {
  service: string;
  url: string;
  nightMode: boolean;
}


function SocialIcon({
  service,
  url,
  nightMode
}: SocialIconProps) {
  const icon = useRef<HTMLImageElement>(null);


  const getIconUrlWithColour = (colour: boolean) => {
    return `./assets/images/${service.toLowerCase()}-${colour ? "colour" : "mono"}${colour ? "" : nightMode ? "-night": "-day"}.svg`;
  }


  const onHover = () => {
    icon.current!.src = getIconUrlWithColour(true);
  }


  const onHoverAway = () => {
    icon.current!.src = getIconUrlWithColour(false);
  }


  return <div className="social-icon">
    <a href={url} target="_blank" rel="noreferrer" title={service}>
      <img ref={icon} src={getIconUrlWithColour(false)} onMouseOver={onHover} onMouseOut={onHoverAway} alt={service} />
    </a>
  </div>;
}


SocialIcon.propTypes = {
  service: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
  nightMode: PropTypes.bool.isRequired
};


export default SocialIcon;
