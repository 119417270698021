export enum FeatureKind {
  Notes = "notes",
  Studio = "studio",
  Press = "press",
  Books = "books"
}

export enum Language {
  EN = "en",
  LT = "lt"
}
