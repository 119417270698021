import React from "react";
import PropTypes from "prop-types";
import { Language } from "../../enums";

import "./LanguageSwitch.scss";

interface LanguageSwitchProps {
  changeLanguage: (arg: Language) => void;
  nightMode: boolean;
}


function LanguageSwitch({
  changeLanguage,
  nightMode
}: LanguageSwitchProps) {
  return <div className={`language-switch${nightMode ? " night" : ""}`}>
    <button onClick={() => changeLanguage(Language.EN)} title="English">EN</button>
    <button onClick={() => changeLanguage(Language.LT)} title="Lietuviškai">LT</button>
  </div> ;
}


LanguageSwitch.propTypes = {
  changeLanguage: PropTypes.func.isRequired
}


export default LanguageSwitch;
