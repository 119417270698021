//noinspection Annotator

import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import ToCome from "../ToCome/ToCome";
import { SUBSTACK_URL } from "../../constants";
import { FeatureKind } from "../../enums";

import "./Feature.scss";

interface FeatureProps {
  kind: FeatureKind,
  nightMode: boolean
}


function Feature({
  kind,
  nightMode
}: FeatureProps) {
  const urls = {
    [FeatureKind.Notes]: SUBSTACK_URL,
    [FeatureKind.Studio]: null,
    [FeatureKind.Press]: null,
    [FeatureKind.Books]: null
  }

  const [displayToCome, setDisplayToCome] = useState(false);
  const [logoClassName, setLogoClassName] = useState("");
  const [textClassName, setTextClassName] = useState("");
  const { t } = useTranslation();

  useEffect(() => {
    if(nightMode) {
      setLogoClassName("feature-logo night");
      setTextClassName("feature-text night");
    } else {
      setLogoClassName("feature-logo");
      setTextClassName("feature-text");
    }
  }, [nightMode])


  const getTranslationKey = () => "features." + kind;


  const createLink = (content: JSX.Element, useClass = false) => {
    let className;
    if(useClass) {
      className = "feature-text-link";
    }

    return (
      <a className={className} href={urls[kind]!} title={t(getTranslationKey())!} target="_blank" rel={"noreferrer"}>
        {content}
      </a>
    );
  };


  const onHover = () => {
    setDisplayToCome(true);
  }


  const onHoverAway = () => {
    setDisplayToCome(false);
  }


  const logoImage =  <img src={`./assets/images/do-${kind}-${nightMode ? "night" : "day"}.svg`} alt={t(getTranslationKey())!} />;
  const logo = urls[kind] ? createLink(logoImage): logoImage;
  const textFragment = <>{t(getTranslationKey())}</>;
  const text = urls[kind] ? createLink(textFragment, true) : textFragment;


  return <div className="feature">
    <div className={logoClassName} onMouseOver={onHover} onMouseOut={onHoverAway}>
      {logo}
    </div>

    <div className={textClassName} onMouseOver={onHover} onMouseOut={onHoverAway}>
      {text}
    </div>

    {!urls[kind] && displayToCome && <ToCome nightMode={nightMode} />}
  </div>
}


const FeatureKindPropType = PropTypes.oneOf(
  Object.values(FeatureKind) as FeatureKind[]
);
Feature.propTypes = {
  kind: FeatureKindPropType.isRequired,
  nightMode: PropTypes.bool.isRequired
};


export default Feature;
