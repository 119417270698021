import React from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";

import "./ToCome.scss";

interface ToComeProps {
  nightMode: boolean
}


function ToCome({
  nightMode
}: ToComeProps) {
  const { t } = useTranslation();


  return <div className={`to-come${nightMode ? " night" : ""}`}>
    {t("to come")}
  </div>
}


ToCome.propTypes = {
  nightMode: PropTypes.bool.isRequired
}


export default ToCome;
