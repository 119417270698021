import P5 from "p5";


class Char {
  p5: P5;
  char: string;
  index: number;
  charSize: number;
  fill: number;
  posX: number;
  posY: number;
  toTargetX: number = 0;
  toTargetY: number = 0;
  targetX: number;
  targetY: number;

  cursorX: number = 9999;
  cursorY: number = 9999;

  accelerationX: number = 0;
  accelerationY: number = 0;
  velocityX: number = 0;
  velocityY: number = 0;


  constructor(
    p5: P5,
    char: string,
    index: number,
    charSize: number,
    fill: number,
    x: number,
    y: number
  ) {
    this.p5 = p5;
    this.char = char;
    this.index = index;
    this.charSize = charSize;
    this.fill = fill;
    this.posX = x;
    this.posY = y;
    this.targetX = x;
    this.targetY = y;
  }


  setTarget(x: number, y: number) {
    this.targetX = x;
    this.targetY = y;
  }


  setToTarget(x: number, y: number) {
    this.toTargetX = x;
    this.toTargetY = y;
  }


  setCursor(x: number, y: number) {
    this.cursorX = x;
    this.cursorY = y;
  }


  spread() {
    this.setTarget(this.toTargetX, this.toTargetY);
  }


  force() {
    const THRESHOLD = 50;
    const minGap = this.p5.random(100, 200)
    const forceFactor = this.p5.random(0.01, 0.02);
    const damping = this.p5.random(0.9, 0.95);
    const gapX = this.posX - this.cursorX;
    const gapY = this.posY - this.cursorY;
    const gapDifference = Math.sqrt(gapX * gapX + gapY * gapY);
    const gapDelta = minGap - gapDifference;

    if(gapDifference < minGap) {
      this.accelerationX += (gapX / gapDifference) * gapDelta * forceFactor;
      this.accelerationY += (gapY / gapDifference) * gapDelta * forceFactor;
    }

    this.velocityX += this.accelerationX;
    this.velocityY += this.accelerationY;

    this.velocityX *= damping;
    this.velocityY *= damping;

    const resultX = this.targetX + this.velocityX;
    if(resultX > this.p5.width - THRESHOLD) {
      this.targetX = this.p5.width -  (this.p5.random(THRESHOLD, THRESHOLD * 4));
    } else if(resultX < THRESHOLD) {
      this.targetX =  this.p5.random(THRESHOLD, THRESHOLD * 4);
    } else {
      this.targetX = resultX;
    }

    const resultY = this.targetY + this.velocityY;
    if(resultY > this.p5.height - THRESHOLD) {
      this.targetY = this.p5.height - (this.p5.random(THRESHOLD, THRESHOLD * 4));
    } else if(resultY < THRESHOLD) {
      this.targetY =  this.p5.random(THRESHOLD, THRESHOLD * 4);
    } else {
      this.targetY = resultY;
    }
  }


  reposition(vibrate: boolean = false) {
    const SPEED = 0.05;
    this.posX = this.p5.lerp(this.posX, this.targetX, SPEED);
    this.posY = this.p5.lerp(this.posY, this.targetY, SPEED);

    if(this.posX > this.targetX - 1 && this.posX < this.targetX + 1) {
      if(vibrate) {
        this.posX += this.p5.random(-1, 1);
        this.posY += this.p5.random(-1, 1);
      }
    }

    const retractFactor = this.p5.random(0.002, 0.006);
    const damping = this.p5.random(0.9, 0.95);

    let gapX = this.targetX - this.posX;
    let gapY = this.targetY - this.posY;

    this.accelerationX = gapX * retractFactor;
    this.accelerationY = gapY * retractFactor;

    this.velocityX += this.accelerationX;
    this.velocityY += this.accelerationY;

    this.velocityX *= damping;
    this.velocityY *= damping;

    this.posX += this.velocityX;
    this.posY += this.velocityY;
  }


  repositionWithoutMotion(vibrate: boolean = false) {
    this.posX = this.targetX;
    this.posY = this.targetY;

    if(vibrate) {
      this.posX += this.p5.random(-1, 1);
      this.posY += this.p5.random(-1, 1);
    }

    const retractFactor = this.p5.random(0.002, 0.006);
    const damping = this.p5.random(0.9, 0.95);

    let gapX = this.targetX - this.posX;
    let gapY = this.targetY - this.posY;

    this.accelerationX = gapX * retractFactor;
    this.accelerationY = gapY * retractFactor;

    this.velocityX += this.accelerationX;
    this.velocityY += this.accelerationY;

    this.velocityX *= damping;
    this.velocityY *= damping;

    this.posX += this.velocityX;
    this.posY += this.velocityY;
  }


  display() {
    this.p5.fill(this.p5.color(this.fill));
    this.p5.text(this.char, this.posX, this.posY);
  }
}


export default Char;
