import React, { useState, useEffect, CSSProperties } from "react";
import i18next from "i18next";
import { useTranslation } from "react-i18next";
import SpreadText from "./components/SpreadText/SpreadText";
import Logo from "./components/Logo/Logo";
import Description from "./components/Description/Description";
import Features from "./components/Features/Features";
import LanguageSwitch from "./components/LanguageSwitch/LanguageSwitch";
import NightModeSwitch from "./components/NightModeSwitch/NightModeSwitch";
import PrivacyPolicy from "./components/PrivacyPolicy/PrivacyPolicy";
import { DAY_BACKGROUND, LANGUAGE_LOCAL_KEY, NIGHT_MODE_LOCAL_KEY } from "./constants";
import { Language } from "./enums";

import './App.scss';
import Footer from "./components/Footer/Footer";


export const spreadTextCanvasFixed: CSSProperties = {
  position: "fixed",
  top: "50%",
  bottom: "auto",
  left: "50%",
  transform: "translate(-50%, -50%)"
};
export const spreadTextCanvasMovable: CSSProperties = {
  position: "absolute",
  top: "auto",
  bottom: 0,
  left: "50%",
  transform: "translateX(-50%)"
}


function App() {
  const [language, setLanguage] = useState(localStorage.getItem(LANGUAGE_LOCAL_KEY) || Language.LT);
  const [nightMode, setNightMode] = useState(localStorage.getItem(NIGHT_MODE_LOCAL_KEY) === "true" || false);
  const [spreadTextCanvasStyle, setSpreadTextCanvasStyle] = useState(spreadTextCanvasFixed);
  const [triggerLogoReplay, setTriggerLogoReplay] = useState(0);
  const [displayPrivacy, setDisplayPrivacy] = useState(false);
  const { t } = useTranslation();


  const changeCanvasStyle = (style: CSSProperties) => {
    setSpreadTextCanvasStyle(style);
  }


  useEffect(() => {
    window.scroll(0, 0);

    let observer = new IntersectionObserver((entries: IntersectionObserverEntry[]) => {
      entries.forEach((entry: IntersectionObserverEntry) => {
        if(entry.target.classList.contains("logo-zone")) {
          if(entry.isIntersecting) {
            setSpreadTextCanvasStyle(spreadTextCanvasMovable);
            setTriggerLogoReplay(Date.now());
          } else if(!entry.isIntersecting) {
            if(entry.boundingClientRect.y > 0) {
              setSpreadTextCanvasStyle(spreadTextCanvasFixed);
            }
          }
        }
      });
    }, {
      threshold: [0, 1]
    });

    observer.observe(document.querySelector(".logo-zone")!);
  }, []);

  useEffect(() => {
    if(!localStorage.getItem(LANGUAGE_LOCAL_KEY)) {
      localStorage.setItem(LANGUAGE_LOCAL_KEY, language);
    }

    if(!localStorage.getItem(NIGHT_MODE_LOCAL_KEY)) {
      localStorage.setItem(NIGHT_MODE_LOCAL_KEY, nightMode.toString());
    }

    document.body.style.backgroundColor = nightMode ? "black" : DAY_BACKGROUND;
  }, [language, nightMode]);


  const changeLanguage = (language: Language) => {
    i18next.changeLanguage(language)
    .then(() => {
      setLanguage(language);
      localStorage.setItem(LANGUAGE_LOCAL_KEY, language);
    });
  }


  const toggleNightMode = (nightMode: boolean) => {
    setNightMode(nightMode);
    localStorage.setItem(NIGHT_MODE_LOCAL_KEY, nightMode.toString());
  }


  const toggleDisplayPrivacy = (display: boolean) => {
    setDisplayPrivacy(display);
  }


  return (
    <div className="App">
      <div className="spread-text-zone spread-text-char"></div>
      <div className="spread-text-zone spread-text-word"></div>
      <div className="spread-text-zone spread-text-para"></div>
      <div className="logo-zone"></div>

      <div className="scroll-top-container">
        <SpreadText
          text={t("spread text paragraph")}
          desktopTextSize={48}
          desktopLineHeight={50}
          mobileTextSize={32}
          mobileLineHeight={28}
          width={() => window.innerWidth * 0.9}
          height={() => window.innerHeight - 50}
          background={nightMode ? "black" : DAY_BACKGROUND}
          fill={nightMode ? 255: 0}
          className="spread-text"
          desktopParaWidth={0.5}
          mobileParaWidth={1}
          canvasStyle={spreadTextCanvasStyle}
          setCanvasStyle={(style: CSSProperties) => changeCanvasStyle(style)}
        />
      </div>

      <LanguageSwitch
        changeLanguage={(language: Language) => changeLanguage(language)}
        nightMode={nightMode}
      />

      <NightModeSwitch
        toggleNightMode={(nightMode: boolean) => toggleNightMode(nightMode)}
        nightMode={nightMode}
      />

      <Logo nightMode={nightMode} triggerReplay={triggerLogoReplay} />

      <Description nightMode={nightMode} />

      <Features nightMode={nightMode} />

      <Footer nightMode={nightMode} toggleDisplayPrivacy={() => toggleDisplayPrivacy(true)} />

      <PrivacyPolicy display={displayPrivacy} toggleDisplayPrivacy={() => toggleDisplayPrivacy(false)} />
    </div>
  );
}


export default App;
