import React from "react";
import PropTypes from "prop-types";
import Feature from "../Feature/Feature";
import { FeatureKind } from "../../enums";

import "./Features.scss";

interface FeaturesProps {
  nightMode: boolean;
}


function Features({
  nightMode
}: FeaturesProps) {
  const featureKinds = [
    FeatureKind.Notes,
    FeatureKind.Studio,
    FeatureKind.Press,
    FeatureKind.Books
  ];

  return <div className="features">
    {
      featureKinds.map((kind: FeatureKind, index: number) => (
        <Feature kind={kind} key={index} nightMode={nightMode} />
      ))
    }
  </div>
}


Features.propTypes = {
  nightMode: PropTypes.bool.isRequired
};


export default Features;
