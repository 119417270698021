import React from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";

import "./Description.scss";

interface DescriptionProps {
  nightMode: boolean;
}


function Description({
  nightMode
}: DescriptionProps) {
  const { t } = useTranslation();


  return <div className="description">
    <div className={nightMode ? "night" : undefined}>
      <p>
        {t("description.para 1")}
      </p>

      <p>
        {t("description.para 2")}
      </p>
    </div>
  </div>
}


Description.propTypes = {
  nightMode: PropTypes.bool.isRequired
}


export default Description;
