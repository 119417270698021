import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import SocialIcon from "../SocialIcon/SocialIcon";
import {
  EMAIL,
  SUBSTACK_URL,
  INSTAGRAM_URL,
  BE_URL
} from "../../constants";

import "./Footer.scss";

interface FooterProps {
  toggleDisplayPrivacy: () => void,
  nightMode: boolean
}


function Footer({
  toggleDisplayPrivacy,
  nightMode
}: FooterProps) {
  const socials = [
    {
      service: "Substack",
      url: SUBSTACK_URL
    },
    {
      service: "Instagram",
      url: INSTAGRAM_URL
    }
  ];

  const [nightClass, setNightClass] = useState("");
  const { t } = useTranslation();

  useEffect(() => {
    setNightClass(nightMode ? " night" : "");
  }, [nightMode]);


  const getYears = () => {
    const year = new Date().getFullYear();
    return year === 2022 ? "2022" : "2022-" + year;
  }


  return <div className={`footer${nightClass}`}>
    <div>
      {
        socials.map(({ service, url}) => (
          <SocialIcon
            key={service}
            service={service}
            url={url}
            nightMode={nightMode}
          />
        ))
      }
    </div>

    <div className="footer-credits">
      <strong>DayOne.lt</strong> &mdash; Brand New Story<br />
      &copy;{getYears()} DayOne.lt<br />
      Laisvės alėja 5-2, Kaunas, LT-44237, <a href={`mailto:${EMAIL}`}>{EMAIL}</a><br />
      MB „Komunikacijos projektai“, Įm.k. 30324900
    </div>

    <div>
      <button className={`footer-privacy-link${nightClass}`} onClick={toggleDisplayPrivacy}>
        {t("privacy link")}
      </button>
    </div>

    <div className="footer-extra">
      <strong>DayOne.lt</strong> brand and website is<br />
      developed according to the principles of digital ecology.<br />
      Credits: <a href={BE_URL} target="_blank" rel="noreferrer">BE Studio</a>
    </div>
  </div>;
}


Footer.propTypes = {
  toggleDisplayPrivacy: PropTypes.func.isRequired,
  nightMode: PropTypes.bool.isRequired
};


export default Footer;
