import React, { CSSProperties } from "react";
import { useTranslation, Trans } from "react-i18next";
import PropTypes from "prop-types";
import { EMAIL } from "../../constants";

import "./PrivacyPolicy.scss";

interface PrivacyPolicyProps {
  display: boolean,
  toggleDisplayPrivacy: () => void
}


function PrivacyPolicy({
  display,
  toggleDisplayPrivacy
}: PrivacyPolicyProps) {
  const { t } = useTranslation();


  return <div
    className="privacy-policy"
    style={{
      display: display ? "block" : "none"
    } as CSSProperties}
  >
    <h1>{t("privacy policy.title")}</h1>

    <div className="privacy-policy-content">
      <p>{t("privacy policy.para 1")}</p>
      <p>{t("privacy policy.para 2")}</p>
      <Trans i18nKey="privacy policy.para 3">
        .<a href={`mailto:${EMAIL}`}>Link</a>
      </Trans>
    </div>

    <div className="privacy-policy-close" onClick={toggleDisplayPrivacy} title="Close">
      <img src="./assets/images/close.svg" alt="Close" />
    </div>
  </div>;
}


PrivacyPolicy.propTypes = {
  display: PropTypes.bool.isRequired,
  toggleDisplayPrivacy: PropTypes.func.isRequired
}


export default PrivacyPolicy;
