import React, { useRef, useEffect, CSSProperties } from "react";
import PropTypes from "prop-types";
import P5 from "p5";
import Char from "./Char";
import { spreadTextCanvasFixed } from "../../App";
import { FONT_SOURCE } from "../../constants";

import "./SpreadText.scss";

interface SpreadTextProps {
  text: string;
  desktopTextSize: number;
  desktopLineHeight: number;
  mobileTextSize: number;
  mobileLineHeight: number;
  width: () => number;
  height: () => number;
  background: string;
  fill: number;
  className: string;
  desktopParaWidth?: number;
  mobileParaWidth?: number;
  canvasStyle: CSSProperties;
  setCanvasStyle: (style: CSSProperties) => void;
}

enum Mode {
  Paragraph,
  Words,
  Characters
}


function SpreadText({
  text,
  desktopTextSize,
  desktopLineHeight,
  mobileTextSize,
  mobileLineHeight,
  width,
  height,
  background,
  fill,
  className,
  desktopParaWidth = 1,
  mobileParaWidth = 1,
  canvasStyle,
  setCanvasStyle
}: SpreadTextProps) {
  const chars = useRef<Char[]>([]);
  const words = useRef<{
    width: number,
    x: number,
    y: number
  }[]>([]);
  const splitChars = useRef<string[]>([]);
  const wordBreakIndices = useRef<number[]>([0]);
  const canvasContainer = useRef<HTMLDivElement>(null);
  let motion = false;
  const MOBILE_WIDTH = 640;


  const sketch = (p5: P5) => {
    let textFont: P5.Font;
    let mode: Mode = Mode.Characters;


    p5.setup = () => {
      textFont = p5.loadFont(FONT_SOURCE);
      p5.createCanvas(width(), height()).parent(canvasContainer.current!);
      p5.background(p5.color(background));
      p5.textFont(textFont)
      p5.textSize(isMobile() ? mobileTextSize : desktopTextSize);
      p5.fill(fill);

      init();
    };


    p5.draw = () => {
      p5.background(p5.color(background));

      const vibrate = mode === Mode.Characters;
      for (let i = 0; i < chars.current.length; i++) {
        const charItem = chars.current[i];
        motion ? charItem.reposition(vibrate) : charItem.repositionWithoutMotion(vibrate);
        charItem.display();
      }
    };


    p5.mouseMoved = () => {
      if(mode === Mode.Characters) {
        for(let char of chars.current) {
          char.setCursor(p5.mouseX, p5.mouseY);
          char.force();
        }
      }

      setTimeout(() => {
        for(let char of chars.current) {
          char.setCursor(9999, 9999);
        }
      }, 500);
    }


    p5.touchMoved = () => {
      if(mode === Mode.Characters) {
        for(let char of chars.current) {
          char.setCursor(p5.mouseX, p5.mouseY);
          char.force();
        }
      }

      setTimeout(() => {
        for(let char of chars.current) {
          char.setCursor(9999, 9999);
        }
      }, 500);
    }


    const init = () => {
      chars.current = [];
      splitChars.current = text.split("");
      wordBreakIndices.current = [0];
      const charsArr: Char[] = [];

      for(let i = 0; i < splitChars.current.length; i++) {
        const splitChar = splitChars.current[i];

        if(splitChar === " ") {
          wordBreakIndices.current.push(i + 1);
        }

        const x = width() / 2;
        const y = height() / 2;
        const char = new Char(p5, splitChar, i, isMobile() ? mobileTextSize : desktopTextSize, fill, x, y);
        charsArr.push(char);
      }

      chars.current = charsArr;
    };


    const spreadChars = () => {
      for(let char of chars.current) {
        char.spread();
      }
    };


    const setupParaCharPositions = () => {
      const charsArr = chars.current;
      let sentences: { chars: Char[], width: number }[] = [];
      let sentenceIndex = 0;
      let sentenceWidth = 0;
      let sentenceBreakIndex = 0;
      const lineHeight = isMobile() ? mobileLineHeight : desktopLineHeight;

      for(let i = 0; i < wordBreakIndices.current.length; i++) {
        if(i === wordBreakIndices.current.length - 1) {
          sentenceWidth += p5.textWidth(splitChars.current.slice(wordBreakIndices.current[i]).join(""));
        } else {
          sentenceWidth += p5.textWidth(splitChars.current.slice(wordBreakIndices.current[i], wordBreakIndices.current[i + 1]).join(""));
        }

        const paraWidth = window.innerWidth > MOBILE_WIDTH ? desktopParaWidth : mobileParaWidth;
        if(sentenceWidth > width() * paraWidth) {
          sentenceIndex++;
          sentenceWidth = p5.textWidth(splitChars.current.slice(wordBreakIndices.current[i], wordBreakIndices.current[i + 1]).join(""));
          sentenceBreakIndex = wordBreakIndices.current[i];
          if(i === wordBreakIndices.current.length - 1) {
            sentences[sentenceIndex] = {
              chars: charsArr.slice(sentenceBreakIndex),
              width: sentenceWidth
            };
          } else {
            sentences[sentenceIndex] = {
              chars: charsArr.slice(sentenceBreakIndex, wordBreakIndices.current[i + 1]),
              width: sentenceWidth
            };
          }
        } else {
          if(i === wordBreakIndices.current.length - 1) {
            sentences[sentenceIndex] = {
              chars: charsArr.slice(sentenceBreakIndex),
              width: sentenceWidth
            };
          } else {
            sentences[sentenceIndex] = {
              chars: charsArr.slice(sentenceBreakIndex, wordBreakIndices.current[i + 1]),
              width: sentenceWidth
            };
          }
        }
      }

      let y = (height() - (sentences.length * lineHeight)) / 2;
      for(let sentence of sentences) {
        let x = (width() - sentence.width) / 2;


        for(let i = 0; i < sentence.chars.length; i++) {
          charsArr[sentence.chars[i].index].setToTarget(
            x,
            y
          );

          x += p5.textWidth(splitChars.current[sentence.chars[i].index]);
        }

        y += lineHeight;
      }
    }


    const spreadToParagraph = () => {
      mode = Mode.Paragraph;
      setupParaCharPositions();
      spreadChars();
    };


    const setupWordCharPositions = () => {
      const charsArr = chars.current;
      words.current = [];
      const wordsArr = words.current;
      let breakIndex = 0;
      const textSize = isMobile() ? mobileTextSize : desktopTextSize;
      const lineHeight = isMobile() ? mobileLineHeight : desktopLineHeight;

      for(let i = 0; i < charsArr.length; i++) {
        if(wordBreakIndices.current.includes(i)) {
          breakIndex = wordBreakIndices.current.indexOf(i);

          let wordWidth: number;
          if(breakIndex === wordBreakIndices.current.length - 1) {
            wordWidth = p5.textWidth(splitChars.current.slice(i).join(""));
          } else {
            wordWidth = p5.textWidth(splitChars.current.slice(i, wordBreakIndices.current[breakIndex + 1]).join(""));
          }

          // Logic that minimises overlapping of words.
          let collision = true;
          const clearedHeight = lineHeight + 20;

          while(collision) {
            const x = Math.floor(Math.random() * (width() - wordWidth));
            const y = Math.floor(Math.random() * ((height() - textSize - 50) - (lineHeight * 2) + 1)) + (lineHeight * 2);

            if(i === 0) {
              charsArr[i].setToTarget(x, y);
              wordsArr.push({
                width: wordWidth,
                x,
                y
              });
              collision = false;
            } else {
              const checked: boolean[] = [];
              for(let word of wordsArr) {
                checked.push(
                  (
                    (x >= word.x && x <= word.x + word.width)
                    || (x + wordWidth >= word.x && x + wordWidth <= word.x + word.width)
                  )
                  && (
                    (y >= word.y && y <= word.y + clearedHeight)
                    || (y + lineHeight >= word.y && y + lineHeight <= word.y + clearedHeight)
                  )
                );
              }

              if(checked.every(value => !value)) {
                charsArr[i].setToTarget(x, y);
                wordsArr.push({
                  width: wordWidth,
                  x,
                  y
                });
                collision = false;
              }
            }
          }
        } else {
          const wordBreakIndex = wordBreakIndices.current[breakIndex];
          charsArr[i].setToTarget(
            charsArr[wordBreakIndex].toTargetX + p5.textWidth(splitChars.current.slice(wordBreakIndex, (i - wordBreakIndex) + wordBreakIndex).join("")),
            charsArr[wordBreakIndex].toTargetY
          )
        }
      }
    };


    const spreadToWords = () => {
      mode = Mode.Words;
      setupWordCharPositions();
      spreadChars();
    };


    const setupCharPositions = () => {
      const charsArr = chars.current;
      const textSize = isMobile() ? mobileTextSize : desktopTextSize;
      const lineHeight = isMobile() ? mobileLineHeight : desktopLineHeight;

      for(let i = 0; i < charsArr.length; i++) {
        charsArr[i].setToTarget(
          Math.floor(Math.random() * (width() - p5.textWidth(splitChars.current[i]))),
          Math.floor(Math.random() * ((height() - textSize) - (lineHeight * 2)) + 1) + (lineHeight * 2)
        );
      }
    };


    const spreadToChars = () => {
      mode = Mode.Characters;
      setupCharPositions();
      spreadChars();
    };


    const windowResized = () => {
      canvasContainer.current!.style.opacity = "0";
      p5.resizeCanvas(width(), height());
      init();

      switch(mode) {
      case Mode.Paragraph:
        spreadToParagraph();
        break;
      case Mode.Words:
        spreadToWords();
        break;
      case Mode.Characters:
        spreadToChars();
        break;
      default:
        break;
      }

      setTimeout(() => {
        canvasContainer.current!.style.opacity = "1";
      }, 250);
    };


    const isMobile = () => window.innerWidth < MOBILE_WIDTH;


    return {
      spreadToParagraph,
      spreadToWords,
      spreadToChars,
      windowResized
    };
  }


  const hasTouchScreen = () => {
    let hasTouchScreen ;
    if ("maxTouchPoints" in navigator) {
      hasTouchScreen = navigator.maxTouchPoints > 0;
    } else if ("msMaxTouchPoints" in navigator) {
      hasTouchScreen = navigator["msMaxTouchPoints"] > 0;
    } else {
      const mQ = matchMedia?.("(pointer:coarse)");
      if (mQ?.media === "(pointer:coarse)") {
        hasTouchScreen = mQ.matches;
      } else if ("orientation" in window) {
        hasTouchScreen = true;
      } else {
        //@ts-ignore
        const UA = navigator.userAgent;
        hasTouchScreen =
          /\b(BlackBerry|webOS|iPhone|IEMobile)\b/i.test(UA) ||
          /\b(Android|Windows Phone|iPad|iPod)\b/i.test(UA);
      }
    }

    return hasTouchScreen;
  }


  useEffect(() => {
    window.scroll(0, 0);
    setCanvasStyle(spreadTextCanvasFixed);
    const p5 = new P5(sketch, canvasContainer.current!);
    const {
      spreadToParagraph,
      spreadToWords,
      spreadToChars,
      windowResized
    } = sketch(p5);

    canvasContainer.current!.style.opacity = "0";

    setTimeout(() => {
      let observer = new IntersectionObserver((entries: IntersectionObserverEntry[]) => {
        entries.forEach((entry: IntersectionObserverEntry) => {
          if(entry.isIntersecting) {
            if(entry.target.classList.contains("spread-text-para")) {
              spreadToParagraph();
            }
            else if(entry.target.classList.contains("spread-text-word")) {
              spreadToWords();
            } else {
              spreadToChars();
            }
          }
        });
      }, {
        threshold: 0.5
      });

      observer.observe(document.querySelector(".spread-text-para")!);
      observer.observe(document.querySelector(".spread-text-word")!);
      observer.observe(document.querySelector(".spread-text-char")!);
    }, 200);

    setTimeout(() => {
      canvasContainer.current!.style.opacity = "1";
    }, 250);

    setTimeout(() => {
      //eslint-disable-next-line react-hooks/exhaustive-deps
      motion = true;
    }, 500);

    let resizeEvent;
    if(hasTouchScreen()) {
      resizeEvent = "resize touchmove";
    } else {
      resizeEvent = "resize";
    }
    window.addEventListener(resizeEvent, () => windowResized());

    return () => {
      p5.remove();
      window.removeEventListener("resize touchmove", () => windowResized());
      window.removeEventListener("resize", () => windowResized());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [background, text]);


  return <>
    <div
    className={className}
    ref={canvasContainer}
    style={canvasStyle}
  ></div></>;
}


SpreadText.propTypes = {
  text: PropTypes.string.isRequired,
  desktopTextSize: PropTypes.number.isRequired,
  desktopLineHeight: PropTypes.number.isRequired,
  mobileTextSize: PropTypes.number.isRequired,
  mobileLineHeight: PropTypes.number.isRequired,
  width: PropTypes.func.isRequired,
  height: PropTypes.func.isRequired,
  background: PropTypes.string.isRequired,
  fill: PropTypes.number.isRequired,
  className: PropTypes.string.isRequired,
  desktopParaWidth: PropTypes.number,
  mobileParaWidth: PropTypes.number,
  canvasStyle: PropTypes.object.isRequired
};


export default SpreadText;
