import React, { useRef, useEffect } from "react";
import PropTypes from "prop-types";

import "./Logo.scss";

const lottie = require("lottie-web");

interface LogoProps {
  triggerReplay: number;
  nightMode: boolean;
}


function Logo({
  triggerReplay,
  nightMode
}: LogoProps) {
  const logoContainer = useRef<HTMLDivElement>(null);

  useEffect(() => {
    lottie.destroy();

    lottie.loadAnimation({
      container: logoContainer.current,
      renderer: "canvas",
      loop: true,
      autoplay: true,
      path: `./assets/animations/${nightMode ? "logo-night" : "logo-day"}.json`
    });

    return () => lottie.destroy();
  }, [nightMode]);

  useEffect(() => {
    lottie.stop();
    lottie.play();
  }, [triggerReplay])


  return <div className="logo" ref={logoContainer}></div>
}


Logo.propTypes = {
  triggerReplay: PropTypes.number.isRequired,
  nightMode: PropTypes.bool.isRequired
}


export default Logo;
