import React from "react";
import PropTypes from "prop-types";

import "./NightModeSwitch.scss";

interface NightModeSwitchProps {
  toggleNightMode: (arg: boolean) => void;
  nightMode: boolean;
}


function NightModeSwitch({
  toggleNightMode,
  nightMode
}: NightModeSwitchProps) {
  return <div className="night-mode-switch">
    <button onClick={() => toggleNightMode(false)} title="Night Mode Off">
      <img src={`./assets/images/sun-${nightMode ? "night" : "day"}.svg`} alt="Night Mode Off" />
    </button>
    <button onClick={() => toggleNightMode(true)} title="Night Mode On">
      <img src={`./assets/images/moon-${nightMode ? "night" : "day"}.svg`} alt="Night Mode On" />
    </button>
  </div>
}


NightModeSwitch.propTypes = {
  toggleNightMode: PropTypes.func.isRequired,
  nightMode: PropTypes.bool.isRequired
}


export default NightModeSwitch;
